.users-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.max-width-column {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pizzerias .table td,
.pizzerias .table th {
  padding: 5px 20px;
}