.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 120px;
  height: 120px;
  background-image: url('../../../public/images//site/lnpe_spinner.gif');
  background-size: cover;
  background-repeat: no-repeat;
}