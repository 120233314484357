.store h1 {
  font-size: 24px;
  color: #FF5204;
  font-weight: bold;
}

.store h3.promo-title {
  font-size: 20px;
  font-weight: bold;
}

.store img.logo {
  width: 200px;
}

.store .social-icon-container img {
  width: 32px;
}

.store p.address {
  font-size: 20px;
  line-height: 1.3;
}

.store p.phone {
  font-size: 18px;
  color: #EC221F;
}