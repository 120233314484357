.form-container {
  max-width: 800px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 50px;
}

.form-container hr {
  margin-top: 0.1rem;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input.is-invalid,
.form-group textarea.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  margin-top: 0.5rem;
  font-size: 0.875em;
}

@media (max-width: 768px) {
  .form-container {
    padding: 1rem;
  }
}