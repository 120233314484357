.pizzeria-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  padding: 2em;
}

@media (max-width: 767.98px) {
  .main-content {
    display: block;
    height: 300px;
  }

  .content {
    flex: block;
    padding: 20px;
    padding: 2em;
  }
}