.register-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 20px;
}

.register-card {
  padding: 2rem;
  width: 100%;
}

.text-center {
  text-align: center;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.form-label {
  font-weight: bold;
}

.success {
  padding: 10px 30px;
}

.success .title {
  color: #14AE5C;
  font-size: 20px;
}

.success .text {
  font-size: 14px;
  font-weight: 100;
}

a {
  color: black;
}