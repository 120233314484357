.footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #212121;
}

.footer .links a {
  color: #5DEFFF;
  text-decoration: none;
  font-size: 15px;
}

.footer .copyright {
  color: white;
  font-size: 12px;
}

.whatsapp-button-container {
  position: fixed;
  bottom: 10px;
  right: 0;
}

@media (max-width: 767.98px) {
  .footer .footer-logo {
    width: 100px;
  }

  .footer .social-icon-container img {
    width: 12px;
  }

  .footer .links a {
    font-size: 10px;
  }

  .footer .copyright {
    font-size: 7px;
  }
}