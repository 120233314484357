.custom-sidebar {
  height: 100%;
  color: #fff;
  background-color: #FF5204;
  padding: 60px;
  min-height: 100vh;
  height: 100%;
}

.custom-sidebar .logo {
  width: 150px;
}

.custom-sidebar .list-group-item {
  background-color: transparent;
  color: #fff;
  border: none;
}

.custom-sidebar .list-group-item.email {
  font-weight: bold;
}

.custom-sidebar a.list-group-item:hover {
  border-left: 3px solid;
}

@media (max-width: 767.98px) {
  .custom-sidebar {
    height: auto;
    color: #fff;
    background-color: #FF5204;
    padding: 20px;
    min-height: auto;
  }

}