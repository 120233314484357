.places .logo-container {
  width: 179px;
}

.places h3.name {
  font-size: 30px;
  color: #FF5204;
  font-weight: 700;
}

.places p.address {
  font-size: 18px;
  color: black;
}

.places p.phone {
  font-size: 18px;
  color: #EC221F;
}

.places img.logo {
  width: 100%;
}

.places .arrow a {
  color: #FF5204;
}

.places .place {
  cursor: pointer;
}

.filter-container {
  background-color: #F5F5F5;
}

.filter-container input {
  width: 100%;
}

.radius {
  color: #FF5204;
  font-size: 14px;
  font-weight: 700;
}


input[type="range"] {
  margin-top: 6px;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
}

input[type="range"]:hover {
  opacity: 1;
}

/* Style the thumb (handle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #FF5204;
  cursor: pointer;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #FF5204;
  cursor: pointer;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .places .logo-container {
    max-width: 81px;
    max-height: 81px;
  }

  .places h3.name {
    font-size: 18px;
    margin-bottom: 0;
  }

  .places p.address {
    font-size: 10px;
    margin-bottom: 0;
  }

  .places p.phone {
    font-size: 10px;
  }
}