.load-image-example__button {
  background: #36393f;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  outline: solid transparent;
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
}

.load-image-example__button:hover {
  background: #20232a;
}

.select-image-example__button {
  color: white;
  border: none;
  background-color: #EC221F;
  cursor: pointer;
  font-size: 16px;
  outline: solid transparent;
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
}

.cancel-image-example__button {
  color: #20232a;
  border: 1px solid#20232a;
  cursor: pointer;
  font-size: 16px;
  outline: solid transparent;
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
}

.load-image-example__button input {
  display: none;
}