.alert-danger {
  color: #EC221F;
  font-size: 14px;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.alert-danger .icon {
  margin-right: 5px;
}