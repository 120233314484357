.pizzerias-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.max-width-column {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 50%;
  /* Position the tooltip above the text */
  left: 30%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  font-size: 12px;
}

.button.custom-tooltip .tooltiptext {
  top: 20px;
  opacity: .5;
}

.pizzerias .table td,
.pizzerias .table th {
  padding: 5px 20px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}