.home .row {
  margin-bottom: 0;
}

.section-1 {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: #007bff;
  color: white;
  background-image: url('../../public/images/site/background.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section-2 {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #5DEFFF;
}

.section-3 {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FEF500;
}

.home-logo {
  margin-top: -1%;
  width: 75%;
}

.home-logo.mobile {
  width: 100%;
}

.home-logo img {
  width: 100%;
}

.home-button-container {
  width: 400px;
}

.home-link-container {
  text-align: center;
}

.home-link-container a {
  color: white;
  font-size: 20px;
}

.home-map-text {
  color: white;
  font-size: 20px;
  width: 80%;
}

.home-button-container .btn {
  width: 100%;
  line-height: 1.3;
  font-size: 18px;
  ;
}

.home-button-container .btn span {
  width: 100%;
  font-size: 25px;
  ;
}

.btn-home-primary {
  background-color: #5DEFFF;
  border-color: #5DEFFF;
  color: #06076D;
  border-radius: 20px;
  width: 100%;
  font-size: 25px;
}

.btn-home-primary.map {
  background-color: #FFF400;
  border-color: #FFF400;
}

.btn-home-primary:hover {
  background-color: #06076D;
  border-color: #06076D;
  color: #5DEFFF;
}

.btn-home-primary.map:hover {
  background-color: #FFE500;
  border-color: #FFE500;
  color: #06076D;
}

.btn-home-secondary {
  background-color: white;
  border-color: white;
  color: #06076D;
  border-radius: 20px;
  width: 100%;
  font-size: 20px;
}

.btn-home-secondary:hover {
  background-color: #06076D;
  border-color: #06076D;
  color: white;
}

.home-text-container {
  text-align: center;
  width: 90%;
}

.home-text-container p.text-1 {
  font-size: 30px;
  line-height: 1.4;
}

.home-text-container p.text-2 {
  font-size: 40px;
  font-weight: bold;
}

.section-2 .title h2 {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #06076D
}

.section-2 .last-edition-item .item-number {
  font-size: 50px;
  color: #06076D;
  font-weight: bold;
  line-height: 1.2;
}

.section-2 .last-edition-item .item-text {
  font-size: 24px;
  color: #06076D;
  font-weight: regular;
}

.section-2 .last-edition-item {
  width: 280px;
  ;
}

.section-3 .text {
  width: 70%;
}

.section-3 .text p {
  font-size: 30px;
  color: #030B0D;
}

.section-3 .brands .title {
  font-size: 25px;
  font-weight: bold;
  color: #030B0D;
}

@media (max-width: 767.98px) {
  .home-text-container p.text-1 {
    font-size: 15px;
    line-height: 1.5;
  }

  .home-text-container p.text-2 {
    font-size: 20px;
    font-weight: bold;
  }

  .home-button-container {
    width: 300px;
  }

  .home-link-container a {
    font-size: 16px;
  }

  .btn-home-primary {
    font-size: 15px;
  }

  .content {
    padding: 1rem;
  }

  .section-1 {
    width: 100vw;
    min-height: 100vh;
  }

  .section-2 {
    width: 100vw;
    min-height: 100vh;
  }

  .section-2 .last-edition-item {
    width: 100%;
  }

  .section-2 .last-edition-item .item-image {
    height: 70px;
  }

  .section-2 .title h2 {
    font-size: 28px;
  }

  .section-2 .last-edition-item .item-number {
    font-size: 40px;
  }

  .section-2 .last-edition-item .item-text {
    font-size: 18px;
  }

  .section-3 .text p {
    font-size: 18px;
  }

  .section-3 .brands .title {
    font-size: 15px;
  }

  .section-3 .brand-icon img {
    width: 25vw;
  }

}


@media (max-width: 576px) {
  .section-2 .last-edition-item .item-image {
    max-height: 70px;
  }

  .home-button-container .btn {
    width: 100%;
    font-size: 16px;
    line-height: 1.3;
    ;
  }

  .home-button-container .btn span {
    width: 100%;
    font-size: 20px;
    ;
  }

  .home-map-text {
    font-size: 14px;
    width: 100%;
  }
}