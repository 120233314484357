.container {
  padding: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-control {
  margin-bottom: 10px;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  width: 500px;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

tr.no-border td {
  border: none;
}

.btn.btn-outline-secondary {
  color: black;
  border: none;
}

.btn.btn-outline-secondary:hover {
  color: #14AE5C;
  background: none;
}

.btn.btn-outline-danger {
  border: none;
  color: black;
}

.btn.btn-outline-danger:hover {
  color: #EC221F;
  background: none;
}

@media (max-width: 767.98px) {
  .Modal {
    width: 90%;
  }
}