@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

.btn-custom-primary {
  background-color: #E43626;
  border-color: #E43626;
  color: white;
  border-radius: 20px;
}

.btn-custom-primary:hover {
  background-color: #B22D1F;
  border-color: #B22D1F;
  color: white;
}

.btn-custom-secondary {
  background-color: white;
  border-color: #333;
  color: #333;
  border-radius: 20px;
}

.btn-custom-secondary:hover {
  background-color: #333;
  border-color: #333;
  color: white;
}

h2 {
  font-size: 25px;
}

.cms h4 {
  font-size: 20px;
  font-weight: regular;
}

.cms hr {
  border-top: 1px solid black;
  opacity: 1;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  border-radius: 20px;
  margin-top: 5px;
}

.form-check-input[type=radio] {
  border-color: #FF5204;
  --bs-form-check-bg-image: none !important;
}

.form-check-input[type=radio]:checked {
  background-color: #FF5204;
}


.form-check-input[type=checkbox] {
  border-radius: 10px;
  border-color: black;
}

.form-check-input:checked[type=checkbox] {
  background-color: #FF5204;
  border-color: #FF5204;
}

.back-container {
  background-image: url('../../../public/images/cms/background.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 100px 0;
  min-height: 100vh;
}

.form-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 20px;
}

input.form-control::placeholder {
  color: #bbb;
}

.input-group-text {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
  height: 38px;
  margin-top: 5px;
}


@media (max-width: 767.98px) {
  .form-container {
    display: block;
  }
}


.success {
  padding: 10px 30px;
}

.success .title {
  color: #14AE5C;
  font-size: 20px;
}

.success .text {
  font-size: 14px;
  font-weight: 100;
}

.error {
  padding: 10px 30px;
}

.error .title {
  color: #EC221F;
  font-size: 20px;
}

.error .text {
  font-size: 14px;
  font-weight: 100;
}